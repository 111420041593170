<template>
  <v-dialog
    v-model="showDialog"
    width="626"
    class="dialog-style"
    persistent
    :fullscreen="getIsMobile"
  >
    <div class="dialog-style-content">
      <div class="header mb-6">
        <div class="d-flex justify-space-between align-center">
          <span class="text-h5 primaryA--text font-weight-bold">{{
            $t("editArticle.title", {
              articleName: editArticleData.name,
            })
          }}</span>
          <v-img
            @click="closeDialog"
            class="close-icon"
            max-width="20px"
            :src="require('../../../assets/img/CloseIcon.svg')"
          >
          </v-img>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="pt-6">
        <v-select
          class="select-width"
          :items="getAllDepartments"
          :placeholder="$t('common.stations')"
          v-model="selectedDepartmentId"
          item-text="name"
          item-value="id"
          solo
          required
          :rules="rules.department"
          :disabled="editArticleData !== null"
        ></v-select>
        <v-text-field
          name="name"
          id="name"
          type="text"
          :placeholder="$t('editArticle.name')"
          :label="$t('editArticle.name')"
          outlined
          color="accent"
          required
          :rules="rules.name"
          v-model="article.name"
        ></v-text-field>
        <v-select
          v-if="article.category"
          class="select-width"
          :items="allCategories"
          :placeholder="$t('articles.articlesCategories')"
          v-model="article.category.id"
          item-text="category"
          item-value="id"
          solo
        ></v-select>
      </div>
      <div class="d-flex">
        <v-btn @click="closeDialog">{{ $t("suppliers.close") }}</v-btn>
        <v-btn
          :disabled="isFormValid"
          class="ml-3"
          @click="updateArticle"
          color="accent"
          >{{ $t("common.save") }}</v-btn
        >
      </div>
    </div>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "EditUnverifiedArticleModal",
  props: {
    dialog: {},
    editArticleData: {},
    departmentId: {},
    selectedSupplier: {},
  },
  data: () => ({
    showDialog: false,
    article: {},
    rules: {},
    isFormValid: true,
    selectedDepartmentId: null,
  }),
  created() {
    this.showDialog = this.dialog;
    this.rules = {
      name: [
        (val) => (val || "").length > 0 || this.$t("common.requiredField"),
      ],
      storeName: [
        (val) => (val || "").length > 0 || this.$t("common.requiredField"),
      ],
      category: [
        (val) => (val || "").length > 0 || this.$t("common.requiredField"),
      ],
      department: [
        (val) => (val || "").length > 0 || this.$t("common.requiredField"),
      ],
    };
    this.$store.dispatch("admin/getAllArticleCategories");
  },
  mounted() {
    if (this.departmentId) {
      this.selectedDepartmentId = this.departmentId;
    }
    if (this.editArticleData) {
      this.article = JSON.parse(JSON.stringify(this.editArticleData));
    } else {
      this.article = {
        name: "",
        storeName: "",
        note: "",
        category: {},
        defaultPrice: 0,
      };
    }
  },
  methods: {
    closeDialog() {
      this.resetForm();
      this.$emit("onClose");
    },
    resetForm() {
      this.article = {
        name: "",
        storeName: "",
        note: "",
        category: "",
        defaultPrice: 0,
      };
    },
    updateArticle() {
      this.$store
        .dispatch("unverifiedSupplier/updateArticleForSupplier", {
          supplierId: this.selectedSupplier,
          articleId: this.article.id,
          name: this.article.name,
          category: this.article.category,
        })
        .then(() => {
          this.$emit("onSuccessUpdatedArticle");
        });
    },
  },
  computed: {
    ...mapGetters({
      getStations: "userProfile/getStations",
      getAllCategories: "admin/getAllCategories",
    }),
    getIsMobile() {
      return this.$isMobile;
    },
    articleName() {
      return this.article.name;
    },
    articleStoreName() {
      return this.article.storeName;
    },
    articleCategory() {
      return this.article.category;
    },
    getAllDepartments() {
      return this.getStations;
    },
    allCategories() {
      return this.getAllCategories;
    },
  },
  watch: {
    dialog() {
      this.showDialog = this.dialog;
    },
    articleName() {
      this.isFormValid = this.articleName === "" ? true : false;
    },
    articleStoreName() {
      this.isFormValid = this.articleStoreName === "" ? true : false;
    },
    articleCategory() {
      this.isFormValid = this.articleCategory === "" ? true : false;
    },
    editArticleData() {
      if (this.editArticleData) {
        this.article = JSON.parse(JSON.stringify(this.editArticleData));
      } else {
        this.article = {
          name: "",
          storeName: "",
          note: "",
          category: "",
          defaultPrice: "",
        };
      }
    },
    departmentId() {
      if (this.departmentId) {
        this.selectedDepartmentId = this.departmentId;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.dialog-style {
  background-color: #ffffff;
  padding: 24px;
  @media screen and (max-width: 959px) {
    height: 100%;
  }
}
.dialog-style-content {
  background-color: #ffffff;
  padding: 24px;
  height: 100%;
}
.close-icon {
  cursor: pointer;
  width: 18px;
}
.price-input {
  max-width: 200px;
  min-width: 60px;
  position: relative;
}
</style>
