<template>
  <div class="root">
    <div
      class="header position-relative"
      :class="[$isMobile ? 'mt-2' : 'mt-10']"
    >
      <div
        class="d-flex align-center justify-center"
        :class="{ 'flex-column': $isMobile }"
      >
        <div
          :class="[$isMobile ? 'displayAdjustMobile' : 'displayAdjustDesktop']"
        >
          <div class="d-flex align-center justify-space-between, width50">
            <v-text-field
              id="search"
              name="search"
              type="text"
              :label="$t('common.search')"
              append-icon="mdi-magnify"
              v-model="searchValue"
              clearable
              @keyup="onSearchUp"
              @keydown="onSearchDown"
              @click:clear="handleClearSearchField"
            ></v-text-field>
          </div>
          <div class="ml-2 width50">
            <v-select
              v-model="selectedCategory"
              :items="allArticlesCategories"
              item-text="category"
              item-value="id"
              @change="onFilterSelected"
            ></v-select>
          </div>
        </div>

        <div class="d-flex justify-center hide-on-mobile">
          <v-checkbox
            class="ml-2 pt-0"
            :label="$t('articles.favoriteOnly')"
            v-model="isFavoriteFilterSelected"
          ></v-checkbox>
          <v-checkbox
            class="ml-2 pt-0"
            :label="$t('articles.showRecommendedQuantity')"
            v-model="isRecommendedQuantityVisible"
          ></v-checkbox>
        </div>
      </div>
      <div class="d-flex justify-center hide-on-desktop">
        <v-checkbox
          :class="{ mt0: $isMobile }"
          class="ml-2 pt-0"
          :label="$t('articles.favoriteOnly')"
          v-model="isFavoriteFilterSelected"
        ></v-checkbox>
        <v-checkbox
          :class="{ mt0: $isMobile }"
          class="ml-2 pt-0"
          :label="$t('articles.showRecommendedQuantity')"
          v-model="isRecommendedQuantityVisible"
        ></v-checkbox>
      </div>
    </div>

    <div
      class="new-order-table-wrapper"
      @scroll="handleScroll"
      id="article-list"
      :class="{ 'adjust-max-height-for-list': isScrolling }"
    >
      <div
        v-if="orderWithSameDate.length > 0"
        class="d-flex justify-center mb-5"
      >
        <v-banner
          color="positive1"
          icon="mdi-information-outline"
          elevation="6"
          rounded
          :single-line="!getIsMobile"
          icon-color="primaryB"
        >
          <div class="d-flex align-center">
            <span class="banner-text primaryLight--text font-weight-medium">
              {{
                $t("newOrder.existingOrderBannerText", {
                  date: this.order.date,
                })
              }}
            </span>
            <v-btn
              class="ml-3"
              raised
              outlined
              color="primaryB"
              small
              @click="handlePreviewOrder"
              >{{ $t("newOrder.view") }}</v-btn
            >
          </div>
        </v-banner>
      </div>
      <v-btn
        v-if="selectedSupplier.isMarket"
        class="btn-all-articles-supplier"
        @click="showAllArticlesSupplier"
        small
        >{{ $t("newOrder.allArticlesSupplier") }}</v-btn
      >
      <v-data-table
        v-if="!noArticles && !getIsMobile"
        ref="my-table"
        :headers="headers"
        :items="allArticles"
        class="elevation-0 adjust-table-height"
        :loading="isDataLoading"
        :loading-text="$t('common.loadingTableText')"
        :mobile-breakpoint="320"
        :footer-props="{
          itemsPerPageAllText: $t('common.all'),
          itemsPerPageText: $t('common.itemsPerPage'),
          itemsPerPageOptions: [10, 20, 30],
        }"
        :dense="getIsMobile"
        :options.sync="options"
        :server-items-length="getTotalProductsCount"
        @update:page="handlePageChange"
        :items-per-page="pageSize"
        @update:items-per-page="onItemsPerPageChange"
        :fixed-header="true"
        :page.sync="pageNumber"
      >
        <template v-slot:[`item.name`]="{ item }">
          <div v-if="!item.noteActive" @click="onArticleNoteSelected(item)">
            <div class="d-flex align-top">
              <div
                v-if="item.isFavorite"
                class="subtitle-1-text primaryA--text font-mobile"
                style="cursor: pointer"
              >
                <strong> {{ item.name }}</strong>
              </div>
              <div
                v-else
                class="subtitle-1-text primaryA--text font-mobile"
                style="cursor: pointer"
              >
                {{ item.name }}
              </div>
              <div class="mr-5">
                <v-icon v-if="item.note">mdi mdi-note</v-icon>
              </div>
            </div>
          </div>
          <div v-else-if="item.noteActive" class="mt-4 mr-2">
            <v-text-field
              class="item-note-width"
              :id="`note-${item.id}`"
              name="note"
              type="text"
              v-model="item.note"
              :label="$t('newOrder.noteLabel')"
              dense
              :append-outer-icon="
                item.note ? 'mdi-content-save' : 'mdi-content-save'
              "
              :append-icon="item.note ? 'mdi-close' : ''"
              @click:append-outer="onCloseEditNote(item)"
              @click:append="clearItemNote(item)"
              @keyup="onItemNoteChangeUp($event, item)"
              @keydown="onItemNoteChangeDown"
            ></v-text-field>
          </div>
        </template>
        <template v-slot:[`item.amount`]="{ item }">
          <div
            class="d-flex flex-column align-center amount-text-field-wrapper"
          >
            <input
              class="amount-text-field"
              type="text"
              v-model="item.amount"
              @input="validateInput(item)"
              @paste.prevent
              inputmode="decimal"
              pattern="[0-9]*\.?[0-9]*"
            />
            <span class="recommended-qty-hint">{{
              item.recommendedQuantity > 0 && isRecommendedQuantityVisible
                ? "*" + item.recommendedQuantity
                : ""
            }}</span>
          </div>
        </template>
      </v-data-table>
      <div v-else-if="getIsMobile && !noArticles">
        <hr class="horizontal-line" />
        <div class="article-item-wrapper">
          <order-article-item
            v-for="(article, index) in allArticles"
            :key="index"
            :article="article"
            @validateInput="validateInput"
            :isRecommendedQuantityVisible="isRecommendedQuantityVisible"
            :showNoteInput="true"
            :showQtyInput="true"
            @onClearItemNote="clearItemNote"
            @onItemNoteChangeUp="onItemNoteChangeUp"
            @onItemNoteChangeDown="onItemNoteChangeDown"
            @onShowItemNote="onArticleNoteSelected"
          ></order-article-item>
        </div>
      </div>
      <no-results-content
        v-else-if="noArticles"
        :noResultsText="$t('common.noResuls')"
        :page="'stepTwoList'"
        :isFavoriteOnlySearch="isFavoriteFilterSelected && !searchValue"
        :supplierId="selectedSupplier.id"
        :departmentId="departmentId"
        :requestedArticleName="searchValue"
        :isSearchInitiated="getIsSearchInitiated"
        @onSuccessRequestSent="handleOnSuccessRequestSent"
        :isMarketSupplier="selectedSupplier.isMarket"
        :isAdmin="isUserAdmin"
      ></no-results-content>
      <div
        class="d-flex justify-center align-center"
        v-if="
          getDepartmentArticlesStatus === 'REQUEST_SUCCESS' &&
          getAllArticles &&
          getAllArticles.length === 0
        "
      ></div>
    </div>
    <div class="footer">
      <div class="d-flex justify-center" v-if="isDataLoading">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <div
        v-else-if="!isScrolling"
        class="d-flex justify-center align-center mt-5"
      >
        <v-btn
          :disabled="!isformValid"
          class="button-width-transform mr-2"
          color="primaryB"
          @click="onSaveClick"
          >{{ $t("newOrder.save") }}</v-btn
        >
        <v-btn
          :disabled="!isformValid"
          color="accent"
          @click="onContinueClick"
          >{{ $t("newOrder.continue") }}</v-btn
        >
      </div>
    </div>
    <edit-article-modal
      v-if="showAddMoreArticlesModal"
      :dialog="showAddMoreArticlesModal"
      @onClose="handleCloseAddMoreArticlesModal"
      @onSuccessAddedArticle="handleOnSuccessAddArticle"
      :departmentId="station.id"
    ></edit-article-modal>
    <preview-orders-for-merge-modal
      v-if="showPreviewOrdersModal"
      :showPreviewOrdersModal="showPreviewOrdersModal"
      :orderWithSameDate="orderWithSameDate"
      :selectedArticles="selectedArticles"
      :orderToMerge="orderToMerge"
      :goToOrderItem="goToOrderItem"
      @onGoingToOrderWarningModal="handleOnGoingToOrder"
      @onMergeToOrderWarningModal="handleOnMergeToOrder"
      @onClose="onClosePreviewOrdersModal"
    ></preview-orders-for-merge-modal>
    <change-merge-warning-modal
      v-if="showGoingToOrderWarningModal"
      :showGoingToOrderWarningModal="showGoingToOrderWarningModal"
      :hideClosing="false"
      :orderToMerge="orderToMerge"
      @onClose="onCloseGoingToOrderWarningModal"
      @onCancel="onCancel"
      @onConfirm="onConfirm"
    ></change-merge-warning-modal>
  </div>
</template>
<script>
// constants
import constants from "../../shared/constants";
import {
  orderStatuses,
  REQUEST_PENDING,
  REQUEST_SUCCESS,
} from "../../shared/statuses";
// vuex
import { mapGetters } from "vuex";
// services
import LocalStorageService from "../../services/local-storage.service";
// components
import PreviewOrdersForMergeModal from "./components/PreviewOrdersForMergeModal.vue";
import ChangeMergeWarningModal from "./components/ChangeMergeWarningModal.vue";
import EditArticleModal from "../Articles/modals/EditArticleModal.vue";
import NoResultsContent from "../../components/NoResultsContent.vue";
import OrderArticleItem from "./components/OrderArticleItem.vue";
import { debounce } from "lodash";

export default {
  name: "StepTwoList",
  components: {
    PreviewOrdersForMergeModal,
    ChangeMergeWarningModal,
    EditArticleModal,
    NoResultsContent,
    OrderArticleItem,
  },
  props: {
    step: {},
    order: {},
    isEdit: {},
    allSteps: {},
    orderStatus: {},
    station: {},
    productRemovedFromCart: {},
    productAmountAdjusted: {},
  },
  data: () => ({
    tags: [],
    searchValue: "",
    headers: [],
    isDataLoading: false,
    unitItems: constants.units,
    qtyItems: constants.numbers20,
    ordersData: [],
    selectedArticles: [],
    departmentId: null,
    selectedCategory: null,
    rules: [],
    selectedSupplier: null,
    showAddMoreArticlesModal: false,
    options: {},
    orderWithSameDate: [],
    showPreviewOrdersModal: false,
    showGoingToOrderWarningModal: false,
    goToOrderItem: null,
    orderToMerge: null,
    dataForMerge: null,
    pageNumber: 1,
    typingTimer: null,
    doneTypingInterval: 1000,
    showPreviewSupplierNotes: false,
    amountRegex: /^[0-9]+(\.[0-9]+)?$/,
    regexInvalid: false,
    invalidInputForm: false,
    pageSize: 10,
    isFavoriteFilterSelected: true,
    isRecommendedQuantityVisible: false,
    isEditOrderModified: false,
    allArticles: [],
    isFavoriteUncheckedAutomatically: false,
    debouncedHandleScroll: {},
    isScrolling: false,
    scrollTimeout: null,
    isLoadingMore: false,
  }),
  created() {
    window.addEventListener("resize", this.setArticleListHeight());
    this.selectedSupplier = this.order && this.order.supplier;
    this.departmentId = this.$route.params.departmentID;

    if (!this.selectedSupplier.isMarket) {
      this.$store.dispatch("articles/getAllArticleSupplierCategories", {
        supplierId: this.selectedSupplier.id,
      });
    }

    this.$store.dispatch("userProfile/getUserProfile").then(() => {
      this.order?.supplier?.id &&
        this.$store.dispatch("userProfile/getSupplierNote", {
          supplierId: this.selectedSupplier.id,
          venueId: this.getVenue.id,
        });
    });

    this.selectedSupplier.isMarket
      ? this.fetchAllArticlesMarket(false)
      : this.fetchAllArticles(false);

    if (!this.isEdit && this.order && this.order.articles) {
      this.selectedArticles = this.order.articles;
    }
    let data = LocalStorageService.getLocalStorageData("mergeOrderData");
    if (this.isEdit && data) {
      this.dataForMerge = data;
    }
    this.$store.dispatch("admin/getAllArticleCategories");
  },
  mounted() {
    this.debouncedHandleScroll = debounce(this.handleScroll, 250);
    window.addEventListener("resize", this.setVhUnit);
    window.addEventListener("orientationchange", this.setVhUnit);
    this.setVhUnit();
    this.setArticleListHeight();
    this.tags = [
      {
        id: 0,
        text: this.$t("newOrder.allArticles"),
        value: "all_articles",
      },
    ];
    this.headers = [
      {
        text: this.$t("common.article"),
        value: "name",
        sortable: false,
        width: this.$isMobile ? 300 : 250,
      },
      // {
      //   text: "",
      //   value: "unit",
      //   sortable: false,
      //   width: this.$isMobile ? 40 : 40,
      // },
      {
        text: this.$t("common.amount"),
        value: "amount",
        sortable: false,
        width: 140,
      },
      // {
      //   text: this.$isMobile ? "" : this.$t("common.note"),
      //   value: "note",
      //   sortable: false,
      //   width: this.$isMobile ? 40 : 230,
      // },
    ];
    // this.syncData();

    if (this.isEdit && this.order && this.order.products) {
      this.order.products.forEach((p) => {
        this.selectedArticles.push(p);
      });

      const data = LocalStorageService.getLocalStorageData("newOrderDraft");
      const dataArticles = data.orderObj && data.orderObj.articles;
      let isArticleAddedToCurrent = false;
      if (dataArticles) {
        dataArticles.forEach((a) => {
          isArticleAddedToCurrent = this.selectedArticles.find(
            (f) => a.id === f.id
          );
          if (!isArticleAddedToCurrent) {
            this.selectedArticles.push(a);
          }
        });
        this.$forceUpdate();
      }
      setTimeout(() => {
        this.$emit("onEditOrderAmountSet");
      }, 1000);
    }

    this.rules = [];
    if (this.areSelectedArticlesCleared) {
      this.selectedArticles = [];
      this.$store.dispatch("orders/clearSelectedArticles", false);
    }
  },
  beforeDestroy() {
    // Clear the timeout when the component is destroyed
    if (this.scrollTimeout !== null) {
      clearTimeout(this.scrollTimeout);
    }
  },
  methods: {
    handleScroll(event) {
      const element = event.target;
      if (!this.isScrolling) {
        this.isScrolling = true;
      }

      if (this.scrollTimeout !== null) {
        clearTimeout(this.scrollTimeout);
      }

      this.scrollTimeout = setTimeout(() => {
        this.isScrolling = false;
        this.scrollTimeout = null;
      }, 500);

      const scrollBottom =
        element.scrollHeight - element.scrollTop - element.clientHeight;

      if (
        scrollBottom <= 0 &&
        !this.isLoadingMore &&
        this.getTotalProductsCount > this.allArticles.length
      ) {
        this.loadMoreArticles();
      }
      if (this.getTotalProductsCount === this.allArticles.length) {
        this.isLoadingMore = false;
        this.scrollTimeout = null;
        this.isScrolling = false;
      }
    },
    loadMoreArticles() {
      this.isLoadingMore = true;
      this.pageNumber += 1;
      this.pageSize = 10;
      this.selectedSupplier.isMarket
        ? this.fetchAllArticlesMarket(true)
        : this.fetchAllArticles(true);
    },
    setArticleListHeight() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
    onFilterSelected(event) {
      this.selectedCategory = event;
      this.selectedSupplier.isMarket
        ? this.fetchAllArticlesMarket(false)
        : this.fetchAllArticles(false);
    },
    onArticleNoteSelected(article) {
      this.isEditOrderModified = true;
      this.$set(article, "noteActive", true);
    },
    onCloseEditNote(article) {
      this.$set(article, "noteActive", false);
    },
    onContinueClick() {
      this.$emit("onStepChange", this.step + 1);
      this.$emit("onOrderUpdate", {
        articles: this.selectedArticles,
        orderNote: this.order.orderNote,
        paymentMethod: this.order.paymentMethod,
      });
    },
    onBackClick() {
      this.$emit("onStepChange", this.step - 1, true);
      this.$emit("onOrderUpdate", {
        articles: this.selectedArticles,
        orderNote: this.order.orderNote,
        paymentMethod: this.order.paymentMethod,
      });
    },
    onArticleSelected(article) {
      this.isEditOrderModified = true;
      let i =
        this.selectedArticles.filter((f) => f.id === article.id).length > 0;
      if (!i) {
        this.selectedArticles.push(article);
      } else if (this.dataForMerge) {
        this.selectedArticles.forEach((a) => {
          if (a.id === article.id) {
            this.$set(
              a,
              "amount",
              parseInt(a.amount) + parseInt(article.amount)
            );
          }
        });
      }

      LocalStorageService.setLocalStorageData("orderChanged", true);
      this.setLocalStorage();
      this.$emit("onAmountChange");
    },
    goToArticlesPage() {
      localStorage.setItem(
        "cameFromNewOrder",
        JSON.stringify({ id: this.departmentId, show: true })
      );
      this.$router.push("/articles");
    },
    setLocalStorage() {
      let data = LocalStorageService.getLocalStorageData("newOrderDraft");
      if (data) {
        data.orderObj.articles = this.selectedArticles;
        data.allSteps = this.allSteps;
        data.currentStep = this.step;

        LocalStorageService.setLocalStorageData("newOrderDraft", data);
      }
    },
    onSaveClick() {
      this.$emit("onOrderUpdate", {
        articles: this.selectedArticles,
      });
      this.$emit("onSaveOrder");
    },
    showAllArticlesSupplier() {
      this.$store
        .dispatch("userProfile/getDepartments")
        .then(() => (this.showAddMoreArticlesModal = true));
    },
    handleCloseAddMoreArticlesModal() {
      this.showAddMoreArticlesModal = false;
      this.$store.dispatch("articles/getDepartmentArticles", {
        id: this.selectedSupplier.id,
        departmentId: this.departmentId,
        pageNum: this.pageNumber,
      });
    },
    handleClickClear(item) {
      this.selectedArticles = this.selectedArticles.filter(
        (f) => f.id !== item.id
      );
      this.selectedArticles.filter((f) => {
        if (f.id === item.id) {
          this.$set(f, "amount", "");
          this.$set(item, "amount", "");
        }
      });
      this.$forceUpdate();
      this.setLocalStorage();
      this.$emit("onAmountChange");
    },
    handlePageChange(page) {
      this.pageNumber = page;
      this.selectedSupplier.isMarket
        ? this.fetchAllArticlesMarket(false)
        : this.fetchAllArticles(false);
      var table = this.$refs["my-table"];
      var wrapper = table.$el.querySelector("div.v-data-table__wrapper");
      this.$vuetify.goTo(table, { container: wrapper });
    },
    processExistingOrders() {
      this.orderWithSameDate = [];
      if (this.order.supplier.isMarket) {
        this.getAllOrders &&
          this.getAllOrders.forEach((o) => {
            if (
              o.deadline.substr(0, 10) === this.order.date &&
              o.status !== orderStatuses.COMPLETED &&
              o.isCustomOrder
            ) {
              this.orderWithSameDate.push(o);
            }
          });
      } else {
        this.getAllOrders &&
          this.getAllOrders.forEach((o) => {
            if (
              o.deadline.substr(0, 10) === this.order.date &&
              o.status !== orderStatuses.COMPLETED &&
              this.order.supplier?.id === o.supplier?.id
            ) {
              this.orderWithSameDate.push(o);
            }
          });
      }
    },
    handlePreviewOrder() {
      this.showPreviewOrdersModal = true;
    },
    onClosePreviewOrdersModal() {
      this.showPreviewOrdersModal = false;
    },
    getOrderStatusText(status) {
      switch (status) {
        case orderStatuses.IN_PROGRESS:
          return this.$t("common.statusInProgress");
        case orderStatuses.COMPLETED:
          return this.$t("common.statusCompleted");
        case orderStatuses.OPEN:
          return this.$t("common.statusOpen");
      }
    },
    getItemColor(status) {
      switch (status) {
        case orderStatuses.PENDING:
          return "#276EF1";
        case orderStatuses.DELIVERED:
          return "#00A991";
        case orderStatuses.NOT_DELIVERED:
          return "#FD4A34";
      }
    },

    getItemStatusText(status) {
      switch (status) {
        case orderStatuses.PENDING:
          return this.$t("common.statusInProgress");
        case orderStatuses.DELIVERED:
          return this.$t("common.statusCompleted");
        case orderStatuses.NOT_DELIVERED:
          return this.$t("common.statusNotDelivered");
      }
    },
    getOrderStatusColor(status) {
      switch (status) {
        case orderStatuses.IN_PROGRESS:
          return "#276EF1";
        case orderStatuses.COMPLETED:
          return "#00A991";
        case orderStatuses.OPEN:
          return "#FD4A34";
      }
    },
    handleOnGoingToOrder(item) {
      this.orderToMerge = null;
      this.goToOrderItem = item;
      this.showGoingToOrderWarningModal = true;
    },
    handleOnMergeToOrder(item) {
      this.goToOrderItem = null;
      this.orderToMerge = item;
      this.showGoingToOrderWarningModal = true;
    },
    onCloseGoingToOrderWarningModal() {
      this.showGoingToOrderWarningModal = false;
    },
    onConfirm() {
      LocalStorageService.deleteLocalStorageItem("newOrderDraft");
      LocalStorageService.setLocalStorageData("orderChanged", false);
      if (this.orderToMerge) {
        LocalStorageService.setLocalStorageData(
          "mergeOrderData",
          JSON.stringify(this.selectedArticles)
        );
        this.orderToMerge.isCustomOrder
          ? this.$store.dispatch(
              "orders/setCustomOrderToEdit",
              this.orderToMerge
            )
          : this.$store.dispatch("orders/setCustomOrderToEdit", null);
        this.$router.push(
          `/${this.departmentId}/edit-order/${this.orderToMerge.id}`
        );
      } else {
        this.goToOrderItem.isCustomOrder
          ? this.$store.dispatch(
              "orders/setCustomOrderToEdit",
              this.goToOrderItem
            )
          : this.$store.dispatch("orders/setCustomOrderToEdit", null);
        this.$router.push(
          `/${this.departmentId}/edit-order/${this.goToOrderItem.id}`
        );
      }
    },
    onCancel() {
      this.goToOrderItem = null;
      this.orderToMerge = null;
      this.showGoingToOrderWarningModal = false;
    },
    processDataForMerge() {
      if (this.isEdit && this.dataForMerge) {
        this.dataForMerge.forEach((item) => this.onArticleSelected(item));

        this.allArticles.forEach((a) => {
          this.dataForMerge.forEach((f) => {
            if (f.id === a.id) {
              this.$set(a, "selected", true);
              this.$set(a, "amount", f.amount === null ? "" : f.amount);
            }
          });
        });

        this.$forceUpdate();
      }
    },
    fetchAllArticles(isAccumulating) {
      this.$store.dispatch("articles/clearAllArticles");
      const payload = {
        supplierId: this.selectedSupplier.id,
        pageNum: this.pageNumber,
        categoryId: this.selectedCategory,
        searchQuery: this.searchValue,
        departmentId: this.departmentId,
        pageSize: this.pageSize,
        onlyFavorite: this.isFavoriteFilterSelected,
        isAccumulating,
      };
      this.$store.dispatch("articles/getArticlesSupplier", payload).then(
        () => {
          this.isLoadingMore = false;
        },
        (error) => {
          console.log("error", error);
        }
      );
    },
    fetchAllArticlesMarket(isAccumulating) {
      const payload = {
        departmentId: this.departmentId,
        pageNum: this.pageNumber,
        searchQuery: this.searchValue || "",
        pageSize: this.pageSize,
        onlyFavorite: this.isFavoriteFilterSelected,
        categoryName: this.selectedCategory
          ? this.getAllCategories.find(
              (category) => category.id === this.selectedCategory
            )?.category
          : null,
        isAccumulating,
      };
      this.$store.dispatch("articles/getCustomArticles", payload).then(() => {
        this.isLoadingMore = false;
      });
    },
    onSearchUp() {
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(
        this.searchArticles,
        this.doneTypingInterval
      );
    },
    onSearchDown() {
      clearTimeout(this.typingTimer);
    },
    setAmountItem(evt, item) {
      const valueEnterred = evt.target.value;
      const valueValidated =
        valueEnterred !== null ? this.validate(valueEnterred) : true;
      const valueEnterredFloat = parseFloat(valueEnterred);
      if (!valueValidated || valueEnterredFloat < 0.1) {
        this.rules.amount = [""];
        this.invalidInputForm = true;
        this.$set(item, "amountError", [""]);
      } else {
        this.rules.amount = [];
        this.invalidInputForm = false;
        this.$set(item, "amountError", []);
        this.amountChange(valueEnterred, item);
      }
    },
    amountChange(event, item) {
      this.$set(item, "amount", event);
      this.selectedArticles &&
        this.selectedArticles.filter((a) => {
          if (a.id === item.id) {
            this.$set(a, "amount", event);
            this.$set(a, "amountError", item.amountError);
          }
        });
      LocalStorageService.setLocalStorageData("orderChanged", true);
      if (!event) {
        this.selectedArticles = this.selectedArticles.filter(
          (f) => f.id !== item.id
        );
      } else {
        this.onArticleSelected(item);
      }
      this.setLocalStorage();

      this.$forceUpdate();
      this.$emit("onAmountChange");
    },
    validate(s) {
      // let rgx = /^[0-9]*\.?[0-9]*$/;
      const rgx = /^[0-9.]*$/;
      return rgx.test(s);
    },
    searchArticles() {
      this.pageNumber = 1;
      this.pageSize = 10;
      this.selectedSupplier.isMarket
        ? this.fetchAllArticlesMarket(false)
        : this.fetchAllArticles(false);
    },
    handleClearSearchField() {
      this.onSearchUp();
    },
    onItemsPerPageChange(event) {
      this.pageSize = event;
      this.selectedSupplier.isMarket
        ? this.fetchAllArticlesMarket(false)
        : this.fetchAllArticles(false);
    },
    handleOnSuccessAddArticle() {
      this.fetchAllArticlesMarket();
    },
    onItemNoteChangeDown() {
      clearTimeout(this.typingTimer);
    },
    onItemNoteChangeUp(event, item) {
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(
        this.setItemNote(event, item),
        this.doneTypingInterval
      );
    },
    setItemNote(event, item) {
      let valueEnterred = event?.target.value;
      this.selectedArticles?.forEach((article) => {
        if (article.id === item.id) {
          article.note = valueEnterred;
        }
      });
      this.setLocalStorage();
    },
    clearItemNote(item) {
      this.$set(item, "note", "");
      this.selectedArticles.forEach((article) => {
        if (article.id === item.id) {
          this.$set(article, "note", "");
        }
      });
      this.setLocalStorage();
    },
    // validateInput(event, item) {
    //   if (event.key === "e" || event.key === "E") {
    //     event.preventDefault();
    //     return;
    //   }
    //   if (event.keyCode !== 69) {
    //     this.isEditOrderModified = true;

    //     if (item.amount && item.amount.startsWith(".")) {
    //       this.$set(item, "amount", "0" + item.amount);
    //       this.setAmountItem(
    //         { target: { value: "0" + event.target.value } },
    //         item
    //       );
    //     } else {
    //       this.setAmountItem({ target: { value: event.target.value } }, item);
    //     }

    //     this.setLocalStorage();
    //   }
    // },
    validateInput(item) {
      const regex = /^[0-9]*\.?[0-9]*$/;
      if (
        !regex.test(item.amount) ||
        item.amount.startsWith(".") ||
        (item.amount.match(/\./g) || []).length > 1
      ) {
        item.amount = item.amount.slice(0, -1);
      } else {
        this.isEditOrderModified = true;
        this.setAmountItem({ target: { value: item.amount } }, item);
        this.setLocalStorage();
      }
    },
    processDataFromLocalStorage() {
      const data = LocalStorageService.getLocalStorageData("newOrderDraft");
      const dataArticles = data.orderObj && data.orderObj.articles;
      this.allArticles.forEach((a) => {
        dataArticles?.forEach((f) => {
          if (f.id === a.id) {
            this.$set(a, "selected", true);
            this.$set(a, "note", f.note);
            this.$set(a, "amount", f.amount === null ? "" : f.amount);
          }
        });
        if (a.amount === null) {
          this.$set(a, "amount", "");
          this.$set(a, "selected", false);
          delete a.amountError;
          this.setAmountItem({ target: { value: "" } }, a);
        }
      });
      this.allArticles.forEach((a) => {
        dataArticles?.forEach((f) => {
          if (f.id === a.id) {
            this.$set(a, "note", f.note);
          }
        });
      });
    },
    syncData() {
      if (
        this.isEdit &&
        this.order &&
        this.order.products &&
        this.allArticles
      ) {
        this.syncOrderWithArticles();
        this.updateArticlesBasedOnLocalStorage();
      } else if (!this.isEdit && this.order) {
        this.processDataFromLocalStorage();
      }
      this.processExistingOrders();
    },
    syncOrderWithArticles() {
      this.order.products.forEach((f) => {
        this.$set(f, "selected", true);
      });

      this.allArticles.forEach((a) => {
        this.order.products.forEach((f) => {
          if (f.id === a.id) {
            this.$set(a, "selected", true);
            this.$set(a, "note", f.note);
            this.$set(a, "amount", f.amount === null ? "" : f.amount);
          }
        });
      });
    },
    updateArticlesBasedOnLocalStorage() {
      const data = LocalStorageService.getLocalStorageData("newOrderDraft");
      const dataArticles = data.orderObj && data.orderObj.articles;
      if (dataArticles) {
        this.allArticles.forEach((a) => {
          dataArticles.forEach((f) => {
            if (a.id === f.id) {
              this.$set(a, "selected", true);
              this.$set(a, "note", f.note);
              this.$set(a, "amount", f.amount === null ? "" : f.amount);
            }
          });
          if (a.amount === null) {
            this.$set(a, "amount", "");
            this.$set(a, "selected", false);
            delete a.amountError;
            this.setAmountItem({ target: { value: "" } }, a);
          }
        });
        this.allArticles.forEach((a) => {
          dataArticles?.forEach((f) => {
            if (f.id === a.id) {
              this.$set(a, "note", f.note);
            }
          });
        });
      }
    },
    handleOnSuccessRequestSent() {
      this.searchValue = "";
      this.searchArticles();
    },
    setVhUnit() {
      let vh;

      if (window.visualViewport) {
        // Use visualViewport.height if available
        vh = window.visualViewport.height * 0.01;
      } else {
        // Fallback to window.innerHeight
        vh = window.innerHeight * 0.01;
      }
      console.log("VH value debug+++++", vh);
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
  },
  computed: {
    ...mapGetters({
      allOrders: "orders/getAllOrdersState",
      getAllArticlesCategories: "articles/getAllArticlesCategories",
      getAllArticles: "articles/getAllSupplierArticles",
      getDepartmentArticlesStatus: "articles/getAllSupplierArticlesStatus",
      getAllProductsCount: "articles/getTotalArticlesCount",
      getProductsStatus: "articles/getAllProductsStatus",
      getVenue: "userProfile/getVenue",
      getSelectedSupplierNote: "userProfile/getSelectedSupplierNote",
      getAllCustomArticles: "articles/getAllCustomArticles",
      getAllCustomArticlesStatus: "articles/getAllCustomArticlesStatus",
      getAllCustomArticlesCount: "articles/getAllCustomArticlesCount",
      getIsSelectedSupplierMarket: "supplier/getIsSelectedSupplierMarket",
      getAllCategories: "admin/getAllCategories",
      areSelectedArticlesCleared: "orders/getAreSelectedArticlesCleared",
      isUserAdmin: "auth/isUserAdmin",
    }),
    allSupplierNotes() {
      return this.getSelectedSupplierNote;
    },
    getArticles() {
      return this.selectedSupplier?.isMarket
        ? this.getAllCustomArticles
        : this.getAllArticles;
    },
    getArticlesStatus() {
      return this.selectedSupplier.isMarket
        ? this.getDepartmentArticlesStatus
        : this.getAllCustomArticlesStatus;
    },
    isformValid() {
      return (
        this.selectedArticles.length > 0 &&
        this.selectedArticles.filter(
          (article) =>
            article.amount &&
            article.amount >= 0.1 &&
            this.amountRegex.test(article.amount)
        ).length === this.selectedArticles.length &&
        !this.regexInvalid &&
        !this.getIsInputInvalid
      );
    },
    getIsInputInvalid() {
      return this.invalidInputForm;
    },
    getIsMobile() {
      return this.$isMobile;
    },
    getTotalProductsCount() {
      return this.selectedSupplier.isMarket
        ? this.getAllCustomArticlesCount
        : this.getAllProductsCount;
    },
    getAllOrders() {
      return this.allOrders;
    },
    allArticlesCategories() {
      let ctgs = [];
      ctgs = this.getAllCategories;
      ctgs.unshift({ id: null, category: this.$t("articles.all") });
      return ctgs;
    },
    isBasicPlan() {
      return this.$attrs.isBasicPlan;
    },
    isSupplierMarket() {
      return this.getIsSelectedSupplierMarket;
    },
    getAreSelectedArticlesCleared() {
      return this.areSelectedArticlesCleared;
    },
    noArticles() {
      return (
        this.allArticles.length === 0 &&
        this.getDepartmentArticlesStatus != REQUEST_PENDING &&
        this.getAllCustomArticlesStatus !== REQUEST_PENDING
      );
    },
    getIsSearchInitiated() {
      return (
        this.searchValue &&
        (this.getDepartmentArticlesStatus === REQUEST_SUCCESS ||
          this.getAllCustomArticlesStatus === REQUEST_SUCCESS)
      );
    },
  },
  watch: {
    getAllCustomArticlesStatus() {
      this.isDataLoading = this.getAllCustomArticlesStatus === REQUEST_PENDING;

      //this is so that the favorite filter is unchecked automatically when there are no articles and all articles are loaded instead
      if (
        this.getAllCustomArticlesStatus === REQUEST_SUCCESS &&
        this.isFavoriteFilterSelected &&
        !this.getArticles.length &&
        !this.isFavoriteUncheckedAutomatically
      ) {
        this.isFavoriteFilterSelected = false;
        this.isFavoriteUncheckedAutomatically = true;
      }
    },
    getDepartmentArticlesStatus() {
      this.isDataLoading = this.getDepartmentArticlesStatus === REQUEST_PENDING;
      //this is so that the favorite filter is unchecked automatically when there are no articles and all articles are loaded instead
      if (
        this.getDepartmentArticlesStatus === REQUEST_SUCCESS &&
        this.isFavoriteFilterSelected &&
        !this.getArticles.length &&
        !this.isFavoriteUncheckedAutomatically
      ) {
        this.isFavoriteFilterSelected = false;
        this.isFavoriteUncheckedAutomatically = true;
      }
    },
    selectedArticles: {
      handler(val) {
        val &&
          val.forEach((item) => {
            const checkRegexEvent = this.amountRegex.test(item.amount);
            if (!checkRegexEvent) {
              this.regexInvalid = true;
            } else {
              this.regexInvalid = false;
            }
          });

        this.setLocalStorage();
      },
      deep: true,
    },
    getAllOrders: {
      handler() {
        this.processExistingOrders();
      },
      deep: true,
    },
    order: {
      handler() {
        this.syncData();
      },
      deep: true,
    },
    getArticles: {
      handler() {
        this.allArticles = JSON.parse(JSON.stringify(this.getArticles));
        this.allArticles.forEach((a) => {
          const isErrorAmount = this.amountRegex.test(a.amount);
          if (a.amount && a.amount !== null && !isErrorAmount) {
            this.$set(a, "amountError", [""]);
          } else {
            delete a.amountError;
          }
        });
        this.syncData();
      },
      deep: true,
    },
    dataForMerge() {
      if (this.isEdit && this.dataForMerge) {
        this.processDataForMerge();
      }
    },
    getProductsStatus() {
      if (
        this.isEdit &&
        this.dataForMerge &&
        this.getProductsStatus === REQUEST_SUCCESS
      ) {
        this.processDataForMerge();
      }
    },
    isFavoriteFilterSelected() {
      this.pageNumber = 1;
      this.selectedSupplier.isMarket
        ? this.fetchAllArticlesMarket(false)
        : this.fetchAllArticles(false);
    },

    productRemovedFromCart() {
      this.selectedArticles = this.selectedArticles.filter(
        (f) => f.id !== this.productRemovedFromCart?.id
      );
      this.selectedArticles.filter((f) => {
        if (f.id === this.productRemovedFromCart?.id) {
          this.$set(f, "amount", "");
          this.$set(this.productRemovedFromCart, "amount", "");
        }
      });
      this.$forceUpdate();
      this.setLocalStorage();

      const data = LocalStorageService.getLocalStorageData("newOrderDraft");
      if (data && this.productRemovedFromCart) {
        const dataArticles = data.orderObj && data.orderObj.articles;
        this.selectedArticles = dataArticles;
        this.allArticles.forEach((article) => {
          if (article.id === this.productRemovedFromCart.id) {
            this.$set(article, "selected", false);
            this.$set(article, "amount", "");
          }
        });
      }
    },
    productAmountAdjusted: {
      handler() {
        this.selectedArticles.forEach((article) => {
          if (article.id === this.productAmountAdjusted.id) {
            this.$set(article, "amount", this.productAmountAdjusted.amount);
          }
        });
        this.$forceUpdate();
        this.setLocalStorage();
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
:root {
  --vh: 1vh;
}
.header-title {
  width: 100%;
  text-align: center;
}
.chip-active {
  color: #ffffff;
  background: #000000;
}

.search-wrapper {
  min-width: 150px;
}

.remove-uppercase {
  span {
    text-decoration: none !important;
  }
}
.edit-pen-wrapper {
  cursor: pointer;
  background: #f6f7f8;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35%;
  min-height: 35px;
}
.notePresent {
  .img-svg {
    filter: invert(74%) sepia(77%) saturate(7378%) hue-rotate(208deg)
      brightness(92%) contrast(106%);
  }
}
.note-input-wrap {
  position: relative;
  max-width: 366px;
}
.close-note-icon {
  position: absolute;
  top: 6px;
  right: 0;
  background: white;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.buttons-wrap {
  @media screen and (max-width: 585px) {
    flex-direction: column !important;
    margin-top: 40px;
  }
}
.hide-only-on-mobile {
  @media screen and (max-width: 585px) {
    display: none !important;
  }
  @media screen and (min-width: 586px) {
    display: block !important;
  }
}
.show-only-on-mobile {
  @media screen and (max-width: 585px) {
    display: block !important;
    margin-bottom: 24px;
  }
  @media screen and (min-width: 586px) {
    display: none !important;
  }
}
.button-width-transform {
  text-transform: none !important;
}
.position-absolute {
  @media screen and (max-width: 586px) {
    position: absolute;
    justify-content: unset !important;
    left: 0;
    top: -47px;
    overflow: auto;
    max-width: 100%;
  }
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.position-absolute::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.position-relative {
  position: relative;
}
.font-mobile {
  @media screen and (max-width: 660px) {
    font-size: 12px;
  }
}
.amount-text-field-wrapper {
  height: 50px !important;
  width: 100%;
  display: flex;
  align-items: center;
  @media screen and (max-width: 660px) {
    min-width: 55px !important;
    max-width: 55px !important;
  }
}
.amount-text-field {
  outline: none;
  text-align: center;
  @media screen and (max-width: 660px) {
    min-width: 55px !important;
    max-width: 55px !important;
  }
  border-bottom: 1px solid #868585;
  padding-bottom: 2px;
  width: 100%;

  // margin-top: 12px;
  // margin-right: 12px;
}
.item-note-width {
  min-width: 160px;
}
.unit-select {
  position: relative;
  margin-top: 8px;
}
.unit-select-close-icon {
  position: absolute;
  top: 6px;
  right: 4px;
  padding: 4px;
  cursor: pointer;
}
.btn-all-articles-supplier {
  @media screen and(max-width:660px) {
    margin-top: 12px;
    margin-bottom: 12px;
  }
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.banner-text {
  font-size: 16px;
  @media screen and(max-width:660px) {
    font-size: 14px;
  }
}

.width50 {
  width: 50% !important;
}
.width92 {
  width: 50% !important;
}
.x-class {
  text-align: end;
  width: min-content;
  margin: 0;
  margin-right: 6px;
  margin-left: 6px;
}

.hide-on-desktop {
  @media screen and (min-width: 960px) {
    display: none !important;
  }
  @media screen and (max-width: 960px) {
    height: 40px;
  }
}
.hide-on-mobile {
  @media screen and (max-width: 960px) {
    display: none !important;
  }
}

.recommended-qty-hint {
  font-size: 10px;
  padding-top: 4px;
}
.article-item-wrapper {
  // flex: 1 1 auto;
  // overflow-y: auto;
  // max-height: calc((100vh - 210px) * 0.8);
  // overflow-y: auto;
  // overflow-x: hidden;
  // max-height: 65vh;
  // @media screen and(max-width:380px) {
  //   max-height: 50vh;
  // }
  transition: max-height 0.3s ease-in-out;
}

.horizontal-line {
  margin-top: 8px;
  margin-bottom: 8px;
}

.displayAdjustMobile {
  display: flex;
  flex-direction: row;
}
.displayAdjustDesktop {
  display: flex;
  flex-direction: row;
}

.mt0 {
  margin-top: 0 !important;
}

.root {
  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    height: 100vh;
    margin: 0;
    padding-bottom: env(safe-area-inset-bottom);
    padding-bottom: constant(safe-area-inset-bottom);
  }
}

.header {
  flex: 0 0 100px;
}

.new-order-table-wrapper {
  @media screen and (min-width: 960px) {
    max-width: 800px;
    margin: 0 auto;
  }
  @media screen and (max-width: 585px) {
    padding: 8px !important;
  }
  flex: 0 1 calc(var(--vh, 1vh) * 62);
  overflow-y: auto;
  min-height: 0;
  padding-bottom: env(safe-area-inset-bottom);
  padding-bottom: constant(safe-area-inset-bottom);
  transition: flex 0.3s ease;

  // max-height: calc((100vh - 210px) * 0.8);
  // overflow-y: auto;
  // overflow-x: hidden;
  // max-height: 60vh;
  // @media screen and(max-width:380px) {
  //   max-height: 52vh;
  // }
  // transition: max-height 0.3s ease-in-out;
}

.adjust-max-height-for-list {
  flex: 0 0 calc(var(--vh, 1vh) * 72) !important;
}

.footer {
  flex: 0 0 200px;

  padding-bottom: env(safe-area-inset-bottom);
  padding-bottom: constant(safe-area-inset-bottom);
}

.header,
.footer {
  overflow: hidden;
}
</style>
