<template>
  <div class="root">
    <div class="d-flex align-center mb-11">
      <span class="text-h4">{{ $t("adminReports.title") }}</span>
    </div>

    <div class="search-filter">
      <div class="d-flex">
        <DateRangePicker @onDateRangeSelected="handleOnDateRangeSelected" />
        <v-tooltip
          v-model="showInfoTooltip"
          top
          open-on-click
          open-on-hover
          max-width="200"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon color="grey lighten-1">
                mdi-information-slab-circle-outline
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t("adminReports.periodChooseInfo") }}</span>
        </v-tooltip>
      </div>

      <v-text-field
        id="search"
        name="search"
        class="search-input ml-6"
        :placeholder="$t('articles.searchArticles')"
        v-model="searchQuery"
        type="text"
        solo
        prepend-inner-icon="mdi-magnify"
        clearable
        @keyup="onSearchUp"
        @keydown="onSearchDown"
        :disabled="!fromDate || !toDate"
        @click:clear="onSearchUp"
      ></v-text-field>
      <div class="d-flex selects-wrap">
        <v-select
          class="select-width"
          :items="allDepartments"
          :placeholder="$t('common.stations')"
          v-model="selectedDepartmentFilter"
          item-text="name"
          item-value="id"
          solo
          @change="onDepartmentSelected"
          :disabled="!fromDate || !toDate"
        ></v-select>
        <v-select
          :disabled="!selectedDepartmentFilter || !fromDate || !toDate"
          class="select-width-category"
          :items="allCategories"
          :placeholder="$t('articles.articlesCategories')"
          v-model="selectedCategoryFilter"
          item-text="category"
          item-value="id"
          solo
          @change="onCategorySelected"
        ></v-select>
      </div>
    </div>
    <div>
      <v-data-table
        v-if="getReportArticles.length > 0"
        :headers="headers"
        :items="getReportArticles"
        :items-per-page="pageSize"
        class="elevation-1"
        @update:page="handlePageChange"
        @update:items-per-page="handleItemsPerPageChange"
        :footer-props="{
          itemsPerPageAllText: $t('common.all'),
          itemsPerPageText: $t('common.itemsPerPage'),
          itemsPerPageOptions: [10, 20, 30],
        }"
        :mobile-breakpoint="320"
      ></v-data-table>
      <div v-else>
        <span>Nema rezultata</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import DateRangePicker from "@/views/Admin/components/DateRangePicker.vue";
export default {
  name: "AdminReports",
  components: { DateRangePicker },
  data() {
    return {
      departmentId: "",
      fromDate: "",
      toDate: "",
      searchQuery: "",
      pageSize: 10,
      page: 0,
      selectedDepartmentFilter: null,
      selectedCategoryFilter: null,
      headers: [],
      typingTimer: null,
      doneTypingInterval: 1500,
      showInfoTooltip: false,
    };
  },
  created() {
    this.$store.dispatch("userProfile/getDepartments");
    this.$store.dispatch("admin/getAllArticleCategories");
  },
  mounted() {
    this.headers = [
      {
        text: this.$t("adminReports.name"),
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: this.$t("adminReports.total"), value: "total", sortable: false },
    ];
  },
  methods: {
    onDepartmentSelected(departmentId) {
      this.departmentId = departmentId;
      this.page = 0;
      this.searchReports();
    },
    searchReports() {
      if (this.isVenueReportsQuery) {
        this.searchReportsVenue();
      } else if (this.isDepartmentReportsQuery) {
        this.searchReportsPerDepartment();
      } else {
        this.searchReportsWithCategory();
      }
    },
    searchReportsPerDepartment() {
      this.$store.dispatch("reports/getReportData", {
        departmentId: this.departmentId,
        fromDate: this.fromDate,
        toDate: this.toDate,
        searchQuery: this.searchQuery,
        pageSize: this.pageSize,
        page: this.page,
      });
    },
    searchReportsVenue() {
      this.$store.dispatch("reports/getReportDataVenue", {
        venueId: this.venueId,
        fromDate: this.fromDate,
        toDate: this.toDate,
        searchQuery: this.searchQuery,
        pageSize: this.pageSize,
        page: this.page,
      });
    },
    searchReportsWithCategory() {
      this.$store.dispatch("reports/getReportDataPerCategory", {
        departmentId: this.selectedDepartmentFilter,
        categoryId: this.selectedCategoryFilter,
        fromDate: this.fromDate,
        toDate: this.toDate,
        searchQuery: this.searchQuery,
        pageSize: this.pageSize,
        page: this.page,
      });
    },
    handleOnDateRangeSelected(dateRange) {
      this.fromDate = dateRange[0];
      this.toDate = dateRange[1];
      this.page = 0;
      this.searchReports();
    },
    onSearchUp() {
      clearTimeout(this.typingTimer);
      this.page = 0;
      this.typingTimer = setTimeout(
        this.searchReports,
        this.doneTypingInterval
      );
    },
    onSearchDown() {
      clearTimeout(this.typingTimer);
    },
    onCategorySelected() {
      this.page = 0;
      this.searchReports();
    },
    handlePageChange(page) {
      this.page = page;
      this.searchReports();
    },
    handleItemsPerPageChange(event) {
      this.pageSize = event;
      this.searchReports();
    },
  },
  computed: {
    ...mapGetters({
      getStations: "userProfile/getStations",
      reportArticles: "reports/getReportArticles",
      reportData: "reports/getReportData",
      getAllArticlesCategories: "admin/getAllCategories",
      getReportArticlesVenue: "reports/getReportArticlesVenue",
      getReportArticlesPerCategory: "reports/getReportArticlesPerCategory",
    }),
    getReportArticles() {
      if (this.isVenueReportsQuery) {
        return this.getReportArticlesVenue;
      } else if (this.isDepartmentReportsQuery) {
        return this.reportArticles;
      } else {
        return this.getReportArticlesPerCategory;
      }
    },
    getReportData() {
      return this.reportData;
    },
    isDepartmentSelected() {
      return this.selectedDepartmentFilter;
    },
    allCategories() {
      let ctgs = this.getAllArticlesCategories;
      ctgs.unshift({ id: null, category: this.$t("articles.all") });
      return ctgs;
    },
    allDepartments() {
      let ctgs = this.getStations;
      ctgs.unshift({ id: null, name: this.$t("adminReports.allDepartments") });
      return ctgs;
    },
    venueId() {
      return this.$attrs.venue.id;
    },
    isVenueReportsQuery() {
      return !this.selectedDepartmentFilter && !this.selectedCategoryFilter;
    },
    isDepartmentReportsQuery() {
      return this.selectedDepartmentFilter && !this.selectedCategoryFilter;
    },
    isCategoryReportsQuery() {
      return !this.selectedDepartmentFilter && this.selectedCategoryFilter;
    },
    // getTotalReportsCount(){
    //   if (this.isVenueReportsQuery) {
    //     // return this.getReportArticlesVenue;
    //   } else if (this.isDepartmentReportsQuery) {
    //     // return this.reportArticles;
    //   } else {
    //     // return this.getReportArticlesPerCategory;
    //   }
    // },
    getTotalCount() {
      return this.getTotalReportsCount;
    },
  },
};
</script>
<style scoped lang="scss">
.search-filter {
  margin-top: 24px;
  display: flex;
  @media screen and (max-width: 575px) {
    flex-direction: column;
    flex-wrap: wrap;
  }
}
.search-input {
  max-width: 315px;
  height: 54px;
  @media screen and (max-width: 575px) {
    margin-left: 0 !important;
    max-width: 100%;
  }
}
.select-width {
  max-width: 315px;
  margin-left: 24px;
  @media screen and (max-width: 575px) {
    margin-left: 0 !important;
    justify-content: space-between;
    max-width: 49%;
  }
}
.select-width-category {
  max-width: 315px;
  margin-left: 24px;
  @media screen and (max-width: 575px) {
    margin-left: 8px !important;
    justify-content: space-between;
    max-width: 49%;
  }
}
</style>
