<template>
  <div class="root d-flex align-items-custom justify-center">
    <div class="d-flex align-center justify-center flex-column">
      <span class="text-subtitle-1 primaryDark--text mt-7">{{
        getNoText
      }}</span>
      <div
        v-if="isArticlesPage && isAdmin"
        class="d-flex flex-column justify-center"
      >
        <span>{{ $t("articles.requestForArticleCreation") }}</span>
        <v-btn class="mt-5" color="primary" @click="openCreateNewArticleModal">
          {{ $t("unverifiedCreateArticle.createArticles") }}
        </v-btn>
      </div>
    </div>

    <create-article-unverified-modal
      :dialog="showCreateUnverifiedArticleModal"
      @onClose="handleOnCloseUnverifiedArticleModal"
      :supplierId="supplierId"
    ></create-article-unverified-modal>
  </div>
</template>
<script>
import CreateArticleUnverifiedModal from "../views/Articles/modals/CreateArticleUnverifiedModal.vue";

export default {
  name: "NoResultsContent",
  components: { CreateArticleUnverifiedModal },
  props: {
    tabName: {},
    noResultsText: {},
    page: {},
    supplierId: {},
    departmentId: {},
    requestedArticleName: {},
    isFavoriteOnlySearch: {},
    isSearchInitiated: {},
    isMarketSupplier: {},
    isAdmin: {},
  },
  data: () => ({
    showCreateUnverifiedArticleModal: false,
  }),
  created() {},
  methods: {
    openCreateNewArticleModal() {
      this.showCreateUnverifiedArticleModal = true;
    },
    handleOnCloseUnverifiedArticleModal() {
      this.showCreateUnverifiedArticleModal = false;
    },
  },
  computed: {
    getNoText() {
      switch (this.page) {
        case "orders":
          return this.$t("currentOrders.noOrders");

        case "articles":
        case "stepTwoList":
          return this.isSearchInitiated
            ? this.$t("articles.noArticles")
            : this.$t("articles.noArticlesCreated");
        default:
          return this.noResultsText;
      }
    },
    isArticlesPage() {
      return (
        (this.page === "articles" || this.page === "stepTwoList") &&
        !this.isFavoriteOnlySearch &&
        !this.isMarketSupplier
      );
    },
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.root {
  height: calc(100vh - 550px);
}
.align-items-custom {
  align-items: center;
  @media screen and (max-width: 959px) {
    align-items: flex-start !important;
  }
}
</style>
