var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"root"},[_c('div',{staticClass:"d-flex align-center mb-11"},[_c('span',{staticClass:"text-h4"},[_vm._v(_vm._s(_vm.$t("adminReports.title")))])]),_c('div',{staticClass:"search-filter"},[_c('div',{staticClass:"d-flex"},[_c('DateRangePicker',{on:{"onDateRangeSelected":_vm.handleOnDateRangeSelected}}),_c('v-tooltip',{attrs:{"top":"","open-on-click":"","open-on-hover":"","max-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v(" mdi-information-slab-circle-outline ")])],1)]}}]),model:{value:(_vm.showInfoTooltip),callback:function ($$v) {_vm.showInfoTooltip=$$v},expression:"showInfoTooltip"}},[_c('span',[_vm._v(_vm._s(_vm.$t("adminReports.periodChooseInfo")))])])],1),_c('v-text-field',{staticClass:"search-input ml-6",attrs:{"id":"search","name":"search","placeholder":_vm.$t('articles.searchArticles'),"type":"text","solo":"","prepend-inner-icon":"mdi-magnify","clearable":"","disabled":!_vm.fromDate || !_vm.toDate},on:{"keyup":_vm.onSearchUp,"keydown":_vm.onSearchDown,"click:clear":_vm.onSearchUp},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('div',{staticClass:"d-flex selects-wrap"},[_c('v-select',{staticClass:"select-width",attrs:{"items":_vm.allDepartments,"placeholder":_vm.$t('common.stations'),"item-text":"name","item-value":"id","solo":"","disabled":!_vm.fromDate || !_vm.toDate},on:{"change":_vm.onDepartmentSelected},model:{value:(_vm.selectedDepartmentFilter),callback:function ($$v) {_vm.selectedDepartmentFilter=$$v},expression:"selectedDepartmentFilter"}}),_c('v-select',{staticClass:"select-width-category",attrs:{"disabled":!_vm.selectedDepartmentFilter || !_vm.fromDate || !_vm.toDate,"items":_vm.allCategories,"placeholder":_vm.$t('articles.articlesCategories'),"item-text":"category","item-value":"id","solo":""},on:{"change":_vm.onCategorySelected},model:{value:(_vm.selectedCategoryFilter),callback:function ($$v) {_vm.selectedCategoryFilter=$$v},expression:"selectedCategoryFilter"}})],1)],1),_c('div',[(_vm.getReportArticles.length > 0)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.getReportArticles,"items-per-page":_vm.pageSize,"footer-props":{
        itemsPerPageAllText: _vm.$t('common.all'),
        itemsPerPageText: _vm.$t('common.itemsPerPage'),
        itemsPerPageOptions: [10, 20, 30],
      },"mobile-breakpoint":320},on:{"update:page":_vm.handlePageChange,"update:items-per-page":_vm.handleItemsPerPageChange}}):_c('div',[_c('span',[_vm._v("Nema rezultata")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }