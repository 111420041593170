<template>
  <div class="padding-adjust current-orders-container">
    <div class="mobile-centering">
      <span class="text-h5 primaryA--text font-weight-bold">{{
        $t("currentOrders.currentOrders")
      }}</span>
    </div>
    <div class="d-flex justify-end">
      <v-btn
        class="d-none d-md-block create-button"
        color="accent"
        @click="createNewOrder"
        >{{ $t("currentOrders.createNewOrder") }}</v-btn
      >
      <div class="d-flex d-md-none button-plus" @click="createNewOrder">
        <v-img
          max-width="14"
          :src="require('../../assets/img/Plus.svg')"
        ></v-img>
      </div>
    </div>
    <!-- <v-tabs v-if="!isBasicPlan" v-model="selectedTab" centered>
      <div class="tab-height-adjust" @click="tabSelected(0)">
        <v-tab class="item-text text-body-1 font-weight-bold">{{
          $t("currentOrders.orders")
        }}</v-tab>
      </div>
      <div class="tab-height-adjust" @click="tabSelected(1)">
        <v-tab class="item-text text-body-1 font-weight-bold">{{
          $t("currentOrders.customOrders")
        }}</v-tab>
      </div>
    </v-tabs> -->

    <router-view></router-view>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import LocalStorageService from "../../services/local-storage.service";
export default {
  name: "CurrentOrdersContainer",
  data: () => ({
    stationID: null,
    selectedTab: 0,
  }),
  created() {
    this.stationID = this.$route.params?.departmentID;
  },
  mounted() {
    this.selectedTab = this.$route.path.includes("custom-orders") ? 1 : 0;
  },
  methods: {
    tabSelected(event) {
      this.$router
        .push(
          event === 0
            ? `/current-orders/${this.stationID}/orders`
            : `/current-orders/${this.stationID}/custom-orders`
        )
        .catch(() => {});
    },
    createNewOrder() {
      LocalStorageService.deleteLocalStorageItem("newOrderDraft");
      LocalStorageService.deleteLocalStorageItem("orderChanged");
      this.selectedTab === 0
        ? this.$router.push(`/${this.stationID}/new-order`)
        : this.$router.push(`/${this.stationID}/new-custom-order`);
      // this.$router.push(`/${this.stationID}/new-order`);
    },
  },
  computed: {
    ...mapGetters({
      basicPlan: "subscription/isBasicPlan",
    }),
    isBasicPlan() {
      return this.basicPlan;
    },
  },
};
</script>
<style scoped lang="scss">
.padding-adjust {
  padding: 32px;
  margin-top: 32px;
  @media screen and (max-width: 575px) {
    padding: 8px;
  }
}
.create-button {
  text-transform: none !important;
}
.button-plus {
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  background-color: #276ef1;
  cursor: pointer;
  border-radius: 50%;
  &:hover {
    box-shadow: 1px 1px 11px 1px #276ef1;
  }
}
.mobile-centering {
  @media screen and (max-width: 575px) {
    display: flex;
    justify-content: center;
  }
}

.tab-height-adjust {
  height: fit-content;
}
</style>
